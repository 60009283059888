$("#toggle").click(function() {
    $(this).toggleClass("toggle-active");
    $("#overlay").toggleClass("nav-active");
    $("#body").toggleClass("scroll-passive");
    $(".slides-container").toggleClass("is-blured");
    $('.for-filter').toggleClass('is-blured');
    $('.roof-slider').toggleClass('is-blured');
    $('.facade-slider').toggleClass('is-blured');
    $('.design-slider').toggleClass('is-blured');
    $('.industry-slider').toggleClass('is-blured');
    $('.eco-slider').toggleClass('is-blured');
    $('.stone-slider').toggleClass('is-blured');
});
$('#overlay').click(function() {
    $("#overlay").toggleClass("nav-active");
    $("#toggle").toggleClass("toggle-active");
    $("#body").toggleClass("scroll-passive");
    $(".slides-container").removeClass("is-blured");    
    $('.for-filter').removeClass('is-blured');
    $('.roof-slider').removeClass('is-blured');
    $('.facade-slider').removeClass('is-blured');
    $('.design-slider').removeClass('is-blured');
    $('.industry-slider').removeClass('is-blured');
    $('.eco-slider').removeClass('is-blured');
    $('.stone-slider').removeClass('is-blured');

});
$('ul li a').click(function(){
    $("#overlay").toggleClass("nav-active");
    $("#toggle").toggleClass("toggle-active");
    $("#body").toggleClass("scroll-passive");
    $(".slides-container").removeClass("is-blured");    
    $('.for-filter').removeClass('is-blured');
    $('.roof-slider').removeClass('is-blured');
    $('.facade-slider').removeClass('is-blured');
    $('.design-slider').removeClass('is-blured');
    $('.industry-slider').removeClass('is-blured');
    $('.eco-slider').removeClass('is-blured');
    $('.stone-slider').removeClass('is-blured');
});

$(document).on('keydown', function(e) {
    if (e.keyCode == 37) {
        carousel.carousel('prev');
    };
    if (e.keyCode == 39) {
        carousel.carousel('next');
    };
});