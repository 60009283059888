var sound = document.getElementById('sound');
var playlist = [
    {
        name: 'CLASSIC',
        countOfSongs: 5,
        path: 'Classic'
    },
    {
        name: 'ELECTRONIC',
        countOfSongs: 2,
        path: 'Electronic'
    },
    {
        name: 'LOUNGE',
        countOfSongs: 7,
        path: 'Lounge'
    },
    {
        name: 'WORLD',
        countOfSongs: 11,
        path: 'World'
    }

];
var currentPlaylist = playlist[0];

$("#sound").bind('ended', function () {
    var sound = $("#sound");
    playNextTrack()
});

function nextStyle() {
    var sound = $("#sound");
    var isPlay = !document.getElementById('sound').paused;
    var currentPlaylistNum = playlist.indexOf(currentPlaylist);
    if (currentPlaylistNum !== playlist.length - 1) {
        currentPlaylist = playlist[currentPlaylistNum + 1]
    } else {
        currentPlaylist = playlist[0];
    }
    setTimeout(function () {
        document.getElementById('mob-style-name').innerHTML = currentPlaylist.name;
        document.getElementById('musicStyle').innerHTML = currentPlaylist.name;
        sound.attr('src', 'sounds/' + currentPlaylist.path + '/track_1.mp3');
        if (isPlay) {
            document.getElementById('sound').play()
        }
    }, 300)
}
function getCurrentTrack() {
    var sound = $("#sound");
    var soundSrc = sound.attr('src');
    var currentTrack = parseInt(soundSrc.substr(1, soundSrc.length - 4).replace(/\D+/g, ""));
    return currentTrack
}

function playNextTrack() {
    var currentTrack = getCurrentTrack();
    var sound = $("#sound");
    if (currentTrack == currentPlaylist.countOfSongs) {
        sound.attr('src', 'sounds/' + currentPlaylist.path + '/track_1.mp3');
        document.getElementById('sound').play()
    } else {
        currentTrack++;
        sound.attr('src', 'sounds/' + currentPlaylist.path + '/track_' + currentTrack + '.mp3');
        document.getElementById('sound').play()
    }
}

function playPrewTrack() {
    var currentTrack = getCurrentTrack();
    var sound = $("#sound");
    if (currentTrack == 1) {
        sound.attr('src', 'sounds/' + currentPlaylist.path + '/track_' + currentPlaylist.countOfSongs + '.mp3');
        document.getElementById('sound').play()
    } else {
        currentTrack--;
        sound.attr('src', 'sounds/' + currentPlaylist.path + '/track_' + currentTrack + '.mp3');
        document.getElementById('sound').play()
    }
}

function playPauseSound() {
    if (sound.paused) {
        sound.play();
        document.getElementById('musicStyle').innerHTML = currentPlaylist.name;
        document.getElementById('main-speaker').innerHTML = '<i class="fa fa-step-backward mouse-pointer" aria-hidden="true" onclick="playPrewTrack()"></i> <i class="fa fa-pause mouse-pointer" aria-hidden="true" onclick="playPauseSound()"></i> <i class="fa fa-step-forward mouse-pointer" aria-hidden="true" onclick="playNextTrack()"></i>';
    } else {
        sound.pause();
        document.getElementById('musicStyle').innerHTML = '';
        document.getElementById('main-speaker').innerHTML = '<i class="fa fa-play mouse-pointer" aria-hidden="true" onclick="playPauseSound()"></i>';
    }
}

function openMobSoundBar() {
    $('#mobile-sound-bar').addClass('mob-play-active');
    $('#play-button').addClass('mob-play-passive');
    playPauseSound();

}

function closeMobSoundBar() {
    $('#mobile-sound-bar').removeClass('mob-play-active');
    $('#play-button').removeClass('mob-play-passive');
    playPauseSound();

}