/**
 * Created by Murat on 27.11.2017.
 */
function saveScrollData(scrollData) {
    sessionStorage.setItem('savedScroll', JSON.stringify(scrollData));
}
function getScroll() {
    if (sessionStorage.getItem('savedScroll')) {
        var savedScroll = JSON.parse(sessionStorage.getItem('savedScroll'));
        setTimeout(function () {
            if (savedScroll.url === window.location.href) {
                $('.scrollbar-inner').scrollTop(savedScroll.y.scroll);
            } else {
                sessionStorage.removeItem('savedScroll');
            }
        }, 300);
    }
}

function scrolled(y, x) {
    if (y.scroll !== 0) {
        saveScrollData({
            'url': window.location.href,
            'y': y,
            'x': x
        });
    }
}