function sendMail() {
    var name = $('#name').val();
    var tel = $('#tel').val();
    var http = new XMLHttpRequest();
    var url = "action/send-email.php";
    var params = "name=" + name + "&tel=" + tel;
    http.open("POST", url, true);
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    http.onreadystatechange = function () {//Call a function when the state changes.
        $('#email-form').html('<p>Ваши данные отправлены</p>');
        $('#submit-email').remove();
    };
    http.send(params);
}