var rofasApp = angular.module('rofasApp', ['ui.router', 'ngStorage', 'ngAnimate', 'angular-loading-bar', 'anim-in-out']);
rofasApp.config(['$stateProvider', '$urlRouterProvider', 'cfpLoadingBarProvider', function ($stateProvider, $urlRouterProvider, cfpLoadingBarProvider) {
  cfpLoadingBarProvider.includeSpinner = false;

	$urlRouterProvider.otherwise('/home');
	$stateProvider
	// Главная страница
		.state('home', {
			url: '/home',
			templateUrl: 'templates/rofas.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Rofas - roof and facades ";
				}],
			}
		})


		// Страница ROOF
		.state('roof', {
			url: '/roof',
			templateUrl: 'templates/roof.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Rofas Roof ";
				}],
			}
		})
		.state('ruukki', {
			url: '/ruukki',
			templateUrl: 'templates/roof/ruukki.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Ruukii - Rofas ";
				}],
			}
		})
		.state('checker', {
			url: '/checker',
			templateUrl: 'templates/roof/checker.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Checker - Rofas ";
				}],
			}
		})
		.state('falz', {
			url: '/falz',
			templateUrl: 'templates/roof/falz.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Фальцевая кровля - Rofas ";
				}],
			}
		})
		.state('bitum', {
			url: '/bitum',
			templateUrl: 'templates/roof/bitum.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Битумная черепица - Rofas ";
				}],
			}
		})
		.state('metal', {
			url: '/metal',
			templateUrl: 'templates/roof/metal.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Металлочерепица - Rofas ";
				}],
			}
		})
		.state('elems', {
			url: '/elems',
			templateUrl: 'templates/roof/elems.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Элементы безопасности кровли - Rofas ";
				}],
			}
		})
		.state('izol', {
			url: '/izol',
			templateUrl: 'templates/roof/izol.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Гидро-теплоизоляция - Rofas ";
				}],
			}
		})
		.state('mansard', {
			url: '/mansard',
			templateUrl: 'templates/roof/mansard.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Мансардные окна - Rofas ";
				}],
			}
		})
		.state('samorez', {
			url: '/samorez',
			templateUrl: 'templates/roof/samorez.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Саморезы для крепления - Rofas ";
				}],
			}
		})
		.state('vodostok', {
			url: '/vodostok',
			templateUrl: 'templates/roof/vodostok.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Водосточные системы - Rofas ";
				}],
			}
		})
		.state('luxard', {
			url: '/luxard',
			templateUrl: 'templates/roof/luxard.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Luxard - Rofas ";
				}],
			}
		})


		// Страница Фасады
		.state('facade', {
			url: '/facade',
			templateUrl: 'templates/facade.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Фасады - Rofas ";
				}],
			}
		})
		.state('alga', {
			url: '/alga',
			templateUrl: 'templates/facade/alga.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Алга фасады - Rofas ";
				}],
			}
		})
		.state('facade-ruukki', {
			url: '/facade-ruukki',
			templateUrl: 'templates/facade/facade-ruukki.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Фасады Ruukii - Rofas ";
				}],
			}
		})
		.state('rockpanel', {
			url: '/rockpanel',
			templateUrl: 'templates/facade/rockpanel.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Фасады Rockpanel - Rofas ";
				}],
			}
		})
		.state('reynobond', {
			url: '/reynobond',
			templateUrl: 'templates/facade/reynobond.html'
		})
		.state('nedzink', {
			url: '/nedzink',
			templateUrl: 'templates/facade/nedzink.html'
		})
		.state('kme', {
			url: '/kme',
			templateUrl: 'templates/facade/kme.html'
		})
		.state('creaton', {
			url: '/creaton',
			templateUrl: 'templates/facade/creaton.html'
		})
		.state('siding', {
			url: '/siding',
			templateUrl: 'templates/facade/siding.html'
		})


		// Страница Дизайн
		.state('design', {
			url: '/design',
			templateUrl: 'templates/design.html'
		})
		.state('desmain', {
			url: '/desmain',
			templateUrl: 'templates/design/index.html'
		})
		.state('detail', {
			url: '/detail',
			templateUrl: 'templates/design/detail.html'
		})
		.state('work', {
			url: '/work',
			templateUrl: 'templates/design/work.html'
		})


		//
		.state('design-interior', {
			url: '/design-interior',
			templateUrl: 'templates/design/design-interior/design-interior.html'
		})
		.state('design-facade', {
			url: '/design-facade',
			templateUrl: 'templates/design/design-facade/design-facade.html'
		})
		.state('arch-facade', {
			url: '/arch-facade',
			templateUrl: 'templates/design/arch-facade/arch-facade.html'
		})



		// Страница Индастри
		.state('industry', {
			url: '/industry',
			templateUrl: 'templates/industry.html'
		})
		.state('isopan', {
			url: '/isopan',
			templateUrl: 'templates/industry/isopan.html'
		})
		.state('kingspan', {
			url: '/kingspan',
			templateUrl: 'templates/industry/kingspan.html'
		})
		.state('alga-trade', {
			url: '/alga-trade',
			templateUrl: 'templates/industry/alga-trade.html'
		})


		// Страница Eco
		.state('eco', {
			url: '/eco',
			templateUrl: 'templates/eco.html'
		})
		.state('derevyannaya-krovlya', {
			url: '/derevyannaya-krovlya',
			templateUrl: 'templates/eco/derevyannaya-krovlya.html'
		})
		.state('derevyannie-doma', {
			url: '/derevyannie-doma',
			templateUrl: 'templates/eco/derevyannie-doma.html'
		})
		.state('kamishovaya-krovlya', {
			url: '/kamishovaya-krovlya',
			templateUrl: 'templates/eco/kamishovaya-krovlya.html'
		})
		.state('slantsevaya-krovlya', {
			url: '/slantsevaya-krovlya',
			templateUrl: 'templates/eco/slantsevaya-krovlya.html'
		})
		.state('zelenaya-krovlya', {
			url: '/zelenaya-krovlya',
			templateUrl: 'templates/eco/zelenaya-krovlya.html'
		})


		// Страница Stone
		.state('stone', {
			url: '/stone',
			templateUrl: 'templates/stone.html'
		})
		.state('mramor', {
			url: '/mramor',
			templateUrl: 'templates/stone/mramor.html'
		})
		.state('limestone', {
			url: '/limestone',
			templateUrl: 'templates/stone/limestone.html'
		})
		.state('granit', {
			url: '/granit',
			templateUrl: 'templates/stone/granit.html'
		})
		.state('travertin', {
			url: '/travertin',
			templateUrl: 'templates/stone/travertin.html'
		})
		.state('oniks', {
			url: '/oniks',
			templateUrl: 'templates/stone/oniks.html'
		})


		// Акция
		.state('deals', {
			url: '/deals',
			templateUrl: 'templates/deals.html'
		})
		.state('deals-list', {
			url: '/deals-list',
			templateUrl: 'templates/deals-list.html'
		})


		// Контакты
		.state('contacts', {
			url: '/contacts',
			templateUrl: 'templates/contacts.html'
		})

		// О нас
		.state('about-us', {
			url: '/about-us',
			templateUrl: 'templates/about-us.html'
		})

		// О нас
		.state('partners', {
			url: '/partners',
			templateUrl: 'templates/partners.html'
		})



		// Блог
		.state('blog', {
			url: '/blog',
			templateUrl: 'templates/blog.html'
		})
		.state('video', {
			url: '/video',
			templateUrl: 'templates/blog/video.html'
		})
		.state('article', {
			url: '/article',
			templateUrl: 'templates/blog/article.html'
		})
		.state('gallery', {
			url: '/gallery',
			templateUrl: 'templates/blog/gallery.html'
		})
		.state('01-blog-allum-facades', {
			url: '/01-blog-allum-facades',
			templateUrl: 'templates/blog/01-blog-allum-facades.html'
		})
		.state('02-blog-bitumnaya-cherepitca', {
			url: '/02-blog-bitumnaya-cherepitca',
			templateUrl: 'templates/blog/02-blog-bitumnaya-cherepitca.html'
		})
		.state('03-blog-derevyannaya-krovlya', {
			url: '/03-blog-derevyannaya-krovlya',
			templateUrl: 'templates/blog/03-blog-derevyannaya-krovlya.html'
		})
		.state('04-blog-kamishovaya-krovlya', {
			url: '/04-blog-kamishovaya-krovlya',
			templateUrl: 'templates/blog/04-blog-kamishovaya-krovlya.html'
		})
		.state('05-blog-krovelnaya-shahka', {
			url: '/05-blog-krovelnaya-shahka',
			templateUrl: 'templates/blog/05-blog-krovelnaya-shahka.html'
		})
		.state('06-blog-metallocherepitca', {
			url: '/06-metallocherepitca',
			templateUrl: 'templates/blog/06-blog-metallocherepitca.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Металлочерепица в Алматы - Rofas - roof and facades";
				}],
			}
		})
		.state('07-blog-modulnaya-cherepitca', {
			url: '/07-modulnaya-cherepitca',
			templateUrl: 'templates/blog/07-blog-modulnaya-cherepitca.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Модульная металлочерепица Венеция - Rofas - roof and facades";
				}],
			}
		})

		// Кабинет
		.state('login', {
			url: '/login',
			templateUrl: 'templates/cabinet/login.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Вход - Rofas - roof and facades";
				}],
			}
		})
		.state('reg', {
			url: '/reg',
			templateUrl: 'templates/cabinet/reg.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Регистрация партнера - Rofas - roof and facades";
				}],
			}
		})
		.state('downloads', {
			url: '/downloads',
			templateUrl: 'templates/cabinet/downloads.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Загрузки - Rofas - roof and facades";
				}],
			}
		})
		.state('cabinet', {
			url: '/cabinet',
			templateUrl: 'templates/cabinet/cabinet.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Личный кабинет - Rofas - roof and facades";
				}],
			}
		})
	    .state('profileEdit', {
	      url: '/profile_edit',
	      templateUrl: 'templates/cabinet/profile-edit.html',
	      resolve: {
	        'title': ['$rootScope', function ($rootScope) {
	          $rootScope.title = "Изменить данные - Rofas - roof and facades";
	        }],
	      }
	    })
		.state('lost_password', {
			url: '/password',
			templateUrl: 'templates/cabinet/password-recovery.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Восстановление пароля - Rofas - roof and facades";
				}],
			}
		})
		.state('public-offer', {
			url: '/public-offer',
			templateUrl: 'templates/cabinet/public-offer.html',
			resolve: {
				'title': ['$rootScope', function ($rootScope) {
					$rootScope.title = "Публичная оферта - Rofas - roof and facades";
				}],
			}
		});
}]);

rofasApp.service('userSession', ['$localStorage', function($localStorage) {
  var userSessionFactory = {};

  userSessionFactory.checkStorage = function () {
    return (($localStorage.user_id && $localStorage.user_id > 0) && ($localStorage.user_token && $localStorage.user_token.length == 32)) ? true : false;
  }

  userSessionFactory.getData = function () {
    return {
      user_id: $localStorage.user_id,
      user_token: $localStorage.user_token
    };
  }

  userSessionFactory.setData = function (_user_id, _user_token) {
    $localStorage.user_id = _user_id;
    $localStorage.user_token = _user_token;
    return true;
  }

  return userSessionFactory;
}]);

rofasApp.controller('loginCtrl', ['$scope', '$http', '$localStorage', '$state', 'cfpLoadingBar', 'userSession', function ($scope, $http, $localStorage, $state, cfpLoadingBar, userSession) {
  var _userSession = userSession.getData();
  console.log('checkStorage=',userSession.checkStorage());
  console.log('getData=',userSession.getData());

	if (userSession.checkStorage()) {
		$state.go('cabinet');
	} else {
		$scope.responseData = {};

		$scope.formSubmit = function () {
      var request = {
        method: 'POST',
        url: './api/users/signin',
        data: $.param($scope.data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      };

      cfpLoadingBar.start();

      $http(request)
        .then(function (response) {
          $scope.responseData = response.data;
          if (response.data.status == 200) {
            userSession.setData(response.data.user_id, response.data.token);
          	$localStorage.user_id = response.data.user_id;
          	$localStorage.user_token = response.data.token;
            $state.go('cabinet');
            toastr.clear();
          } else {
            toastr.error('Извините с данными учетными данными вход невозможен !')
          }

          cfpLoadingBar.complete();
        }, function (response) {
          toastr.error('Произошла ошибка запроса к серверу !')
          cfpLoadingBar.complete();
          console.log('error response=', response);
        });
		}
	}
}]);

rofasApp.controller('registerCtrl', ['$scope', '$http', '$localStorage', '$state', 'cfpLoadingBar', function ($scope, $http, $localStorage, $state, cfpLoadingBar) {
	var user_id = $localStorage.user_id;
	var user_token = $localStorage.user_token;

	if (user_id > 0 && user_token.length == 32) {
		$state.go('cabinet');
	} else {
		$scope.responseData = {status: 0};
		$scope.data = {};

		$scope.formSubmit = function () {
			$scope.data.birthday = moment($scope.data.bd).format('YYYY-MM-DD');
			var request = {
				method: 'POST',
				url: './api/users/signup',
				data: $.param($scope.data),
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
			};

      cfpLoadingBar.start();

			$http(request)
				.then(function (response) {
					$scope.responseData = response.data;
					if (response.data.status == 200) {
            toastr.success('Вы успешно зарегестрировались, дождитесь пока Ваша учетная запись будет активирована. Вы получите уведомление с паролем в виде SMS и электронного письма.')
            $state.go('login');
					} else {
            toastr.error('Заполните корректно форму регистрации.')
					}
          cfpLoadingBar.complete();
				}, function (resonse) {
          cfpLoadingBar.complete();
					console.log('error response=', response);
				});
		}
	}
}]);

rofasApp.controller('lostPasswordCtrl', ['$scope', '$http', '$localStorage', '$state', function ($scope, $http, $localStorage, $state) {
	var user_id = $localStorage.user_id;
	var user_token = $localStorage.user_token;

	if (user_id > 0 && user_token.length == 32) {
		$state.go('cabinet');
	} else {
    $scope.showForm = true;
    $scope.showMessage = false;
    $scope.showError = false;
    $scope.data = {};

    $scope.formSubmit = function () {
      var request = {
        method: 'POST',
        url: './api/users/forgot',
        data: $.param($scope.data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      };

      $http(request)
        .then(function (response) {
          $scope.responseData = response.data;
          if (response.data.status == 200) {
            $scope.showForm = false;
            $scope.showError = false;
            $scope.showMessage = true;
          } else {
            $scope.showError = true;
          }
        }, function (resonse) {
          console.log('error response=', response);
        });
    }
	}
}]);

rofasApp.controller('cabinetCtrl', ['$scope', '$http', '$localStorage', '$state', 'cfpLoadingBar', function ($scope, $http, $localStorage, $state, cfpLoadingBar) {
	var user_id = $localStorage.user_id;
	var user_token = $localStorage.user_token;

	if (user_id > 0 && user_token.length == 32) {
	  $scope.userData = {};

		$scope.onProfileEdit = function () {
      $state.go('profileEdit');
		}

    $scope.onExit = function () {
      $localStorage.user_id = null;
      $localStorage.user_token = null;
      $state.go('login');
    }

		$scope.updateData = function() {
      var request = {
        method: 'GET',
        url: './api/users/profile?user_id=' + user_id + '&user_token=' + user_token,
      };

      cfpLoadingBar.start();

      $http(request)
        .then(function (response) {
          $scope.responseData = response.data;
          if (response.data.status == 200) {
            $scope.userData = response.data.data;
          } else {
            toastr.error('Произошла ошибка при загрузке данных профиля !')
          }
          cfpLoadingBar.complete();
        }, function (response) {
          toastr.error('Произошла ошибка при загрузке данных профиля !');
          cfpLoadingBar.complete();
          console.log('error response=', response);
        });
    }

    $scope.updateData();
	} else {
		$state.go('login');
	}
}]);

rofasApp.controller('profileEditCtrl', ['$scope', '$http', '$localStorage', '$state', function ($scope, $http, $localStorage, $state) {
  var user_id = $localStorage.user_id;
  var user_token = $localStorage.user_token;

  if (user_id > 0 && user_token.length == 32) {

  } else {
    $state.go('login');
  }
}]);

/*
rofasApp.controller('submitCtrl', function ($scope) {
	$scope.formSubmit = false;
	$scope.subFunc = function () {
		$scope.formSubmit = !$scope.formSubmit;
	}
});
*/
