        // $(() => {
        //     $('home').click();
        // });
        $('.star').on('click', function() {
            $('.notification').css('opacity', 0);
            $('.deals-overlay').toggleClass('deals-active');
            $('.slides-container').toggleClass('is-blured');
            $('.roof-slider').toggleClass('is-blured');
            $('.for-filter').toggleClass('is-blured');
        });
        $('ul li a').on('click', function() {
            $('#toggle').toggleClass('toggle-active');
            $('#overlay').toggleClass('nav-active');
            $("#body").toggleClass("scroll-passive");
        });
        $('.close-deals').on('click', function() {
            $('.deals-overlay').toggleClass('deals-active');
            $('.slides-container').toggleClass('is-blured');
            $('.roof-slider').toggleClass('is-blured');
            $('.for-filter').toggleClass('is-blured');
        });
        $('.deals-cons').on('click', function() {
            $('.deals-overlay').toggleClass('deals-active');
            $('.slides-container').toggleClass('is-blured');
            $('.roof-slider').toggleClass('is-blured');
            $('.for-filter').toggleClass('is-blured');
        });